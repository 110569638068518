import { graphql } from 'gatsby';
import React from 'react';
import Sections from '@connected/Sections';
import SEO from '@connected/SEO';
const ContentPage = ({ data }) => {
    const contentData = data.kontentItemContent;
    if (!contentData) {
        return null;
    }
    return (<>
      <SEO {...contentData.metadata} url={contentData.url} image={contentData.elements?.og_image?.value?.[0]?.url}/>
      <Sections {...contentData.elements?.sections__sections}/>
    </>);
};
export const query = graphql `
  query ContentPageQuery($slug: String!) {
    kontentItemContent(elements: { slug: { value: { eq: $slug } } }) {
      ...SEOFragment
      url
      elements {
        og_image: metadata__page_image {
          value {
            url
          }
        }
        sections__sections {
          linked_items {
            ...LinkedItemsFragment
          }
        }
        standard_content__title {
          value
        }
        standard_content__summary {
          value
        }
      }
    }
  }
`;
export default ContentPage;
